<template>
    <div class="row">
        <div class="col-12">
            {{marker}}
        </div>
        <div class="col-6"></div>
        <div class="col-6">
            <a href="javascript:void(0)" class="btn btn-primary"><i class="las la-plus-circle"></i></a>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        marker: { type: Object, require: true }
    },
    data() {
        return {

        }
    },
}
</script>